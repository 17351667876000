* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: whitesmoke;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 1em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  background-color: #5a18ad;
  max-height: 95%;
  max-width: 95%;
  overflow: auto;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Add this line */
  text-align: center; /* Add this line */
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-content h1 {
  font-size: 2rem; /* adjust as needed */
  color: white;
}

.card-content p {
  font-size: 1rem; /* adjust as needed */
  color: white;
}

.card-content img {
  max-width: 20em;
  height: auto;
}

/* smaller font sizes for smaller screens */
@media (max-width: 600px) {
  .card-content h1 {
    font-size: 1.5rem; /* adjust as needed */
  }

  .card-content p {
    font-size: 0.875rem; /* adjust as needed */
  }
}

.logos {
  display: flex;
  margin: 1em;
}

.logos img {
  width: 2.5em;
}

.btn {
  padding: 1em;
  margin: 1em;
}

/* CSS */
.button-53 {
  background-color: #3dd1e7;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-size: 0.75rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: 0.75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 60%;
  max-width: 460px;
  position: relative;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-53:focus {
  outline: 0;
}

.button-53:after {
  content: "";
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button-53:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button-53 {
    padding: 0.75rem 3rem;
    font-size: 1.25rem;
  }
}

.footer {
  margin: 1em;
  color: violet;
}
.contact {
  color: violet;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.logos-col {
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.logos-col img {
  width: 64px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 75%,
    rgba(90, 24, 173, 1) 100%
  );
}

.gecko {
  display: flex;
  padding: 1em;
}

.gecko img {
  width: 2em;
  padding-left: 0.5em;
}

.disclaimer {
  width: 90%;
  color: lightgray;
}

.jesus {
  display: flex;
  padding: 1em;
  align-items: center;
}

.jesus img {
  width: 2.5em;
  height: 2em;
  padding-left: 0.5em;
}
